export default {
  translation: {
    common: {
      delete: 'Excluir',
      deleteModalTitle: 'Tem certeza de que deseja excluir este item?',
      ok: 'Sim',
      cancel: 'Não',
      total: 'Total',
      rename: 'Renomear',
      name: 'Nome',
      save: 'Salvar',
      namePlaceholder: 'Por favor, insira o nome',
      next: 'Próximo',
      create: 'Criar',
      edit: 'Editar',
      upload: 'Carregar',
      english: 'Inglês',
      portugueseBr: 'Português (Brasil)',
      chinese: 'Chinês Simplificado',
      traditionalChinese: 'Chinês Tradicional',
      language: 'Idioma',
      languageMessage: 'Por favor, insira seu idioma!',
      languagePlaceholder: 'selecione seu idioma',
      copy: 'Copiar',
      copied: 'Copiado',
      comingSoon: 'Em breve',
      download: 'Baixar',
      close: 'Fechar',
      preview: 'Pré-visualizar',
      move: 'Mover',
      warn: 'Aviso',
      action: 'Ação',
      s: 'S',
      pleaseSelect: 'Por favor, selecione',
      pleaseInput: 'Por favor, insira',
      submit: 'Enviar',
      embedIntoSite: 'Incorporar no site',
      previousPage: 'Anterior',
      nextPage: 'Próxima',
    },
    login: {
      login: 'Entrar',
      signUp: 'Inscrever-se',
      loginDescription: 'Estamos muito animados para vê-lo novamente!',
      registerDescription: 'Feliz por tê-lo a bordo!',
      emailLabel: 'Email',
      emailPlaceholder: 'Por favor, insira o email',
      passwordLabel: 'Senha',
      passwordPlaceholder: 'Por favor, insira a senha',
      rememberMe: 'Lembrar-me',
      signInTip: 'Não tem uma conta?',
      signUpTip: 'Já tem uma conta?',
      nicknameLabel: 'Apelido',
      nicknamePlaceholder: 'Por favor, insira o apelido',
      register: 'Criar uma conta',
      continue: 'Continuar',
      title: 'Comece a construir seus assistentes inteligentes.',
      description:
        'Inscreva-se gratuitamente para explorar a tecnologia RAG de ponta. Crie bases de conhecimento e IAs para capacitar seu negócio.',
      review: 'de 500+ avaliações',
    },
    header: {
      knowledgeBase: 'Base de Conhecimento',
      chat: 'Chat',
      register: 'Registrar',
      signin: 'Entrar',
      home: 'Início',
      setting: 'Configurações do usuário',
      logout: 'Sair',
      fileManager: 'Gerenciamento de Arquivos',
      flow: 'Agente',
      search: 'Buscar',
    },
    knowledgeList: {
      welcome: 'Bem-vindo de volta',
      description: 'Quais bases de conhecimento você usará hoje?',
      createKnowledgeBase: 'Criar base de conhecimento',
      name: 'Nome',
      namePlaceholder: 'Por favor, insira o nome!',
      doc: 'Documentos',
      searchKnowledgePlaceholder: 'Buscar',
      noMoreData: 'Isso é tudo. Nada mais.',
    },
    knowledgeDetails: {
      dataset: 'Conjunto de dados',
      testing: 'Teste de recuperação',
      files: 'Arquivos',
      configuration: 'Configuração',
      name: 'Nome',
      namePlaceholder: 'Por favor, insira o nome!',
      doc: 'Documentos',
      datasetDescription:
        '😉 Por favor, aguarde o término da análise do seu arquivo antes de iniciar um chat com IA.',
      addFile: 'Adicionar arquivo',
      searchFiles: 'Buscar seus arquivos',
      localFiles: 'Arquivos locais',
      emptyFiles: 'Criar arquivo vazio',
      webCrawl: 'Rastreamento na web',
      chunkNumber: 'Número de fragmentos',
      uploadDate: 'Data de upload',
      chunkMethod: 'Método de fragmentação',
      enabled: 'Habilitar',
      disabled: 'Desabilitar',
      action: 'Ação',
      parsingStatus: 'Status da análise',
      processBeginAt: 'Início em',
      processDuration: 'Duração',
      progressMsg: 'Progresso',
      testingDescription:
        'Realize um teste de recuperação para verificar se o RAGFlow pode recuperar o conteúdo pretendido para o LLM.',
      similarityThreshold: 'Limite de similaridade',
      similarityThresholdTip:
        'O RAGFlow emprega uma combinação de similaridade de palavras-chave ponderada e similaridade de cosseno vetorial ponderada, ou uma combinação de similaridade de palavras-chave ponderada e pontuação de reranking ponderada durante a recuperação. Este parâmetro define o limite para similaridades entre a consulta do usuário e os fragmentos. Qualquer fragmento com uma pontuação de similaridade abaixo deste limite será excluído dos resultados.',
      vectorSimilarityWeight: 'Peso da similaridade de palavras-chave',
      vectorSimilarityWeightTip:
        'Define o peso da similaridade de palavras-chave na pontuação de similaridade combinada, usada com a similaridade de cosseno vetorial ou com a pontuação de reranking. O total dos dois pesos deve ser igual a 1.0.',
      testText: 'Texto de teste',
      testTextPlaceholder: 'Insira sua pergunta aqui!',
      testingLabel: 'Testando',
      similarity: 'Similaridade híbrida',
      termSimilarity: 'Similaridade de termos',
      vectorSimilarity: 'Similaridade vetorial',
      hits: 'Acertos',
      view: 'Visualizar',
      filesSelected: 'Arquivos selecionados',
      upload: 'Carregar',
      run: 'Analisar',
      runningStatus0: 'Não analisado',
      runningStatus1: 'Analisando',
      runningStatus2: 'Cancelado',
      runningStatus3: 'Sucesso',
      runningStatus4: 'Falha',
      pageRanges: 'Intervalos de páginas',
      pageRangesTip:
        'Intervalo de páginas a serem analisadas; páginas fora deste intervalo não serão processadas.',
      fromPlaceholder: 'de',
      fromMessage: 'Página inicial ausente',
      toPlaceholder: 'até',
      toMessage: 'Página final ausente (excluída)',
      layoutRecognize: 'Reconhecimento de layout',
      layoutRecognizeTip:
        'Use modelos visuais para análise de layout para entender melhor a estrutura do documento e localizar efetivamente títulos, blocos de texto, imagens e tabelas. Se desativado, apenas o texto simples no PDF será recuperado.',
      taskPageSize: 'Tamanho da página da tarefa',
      taskPageSizeMessage: 'Por favor, insira o tamanho da página da tarefa!',
      taskPageSizeTip:
        'Durante o reconhecimento de layout, um arquivo PDF é dividido em fragmentos e processado em paralelo para aumentar a velocidade de processamento. Este parâmetro define o tamanho de cada fragmento. Um tamanho de fragmento maior reduz a probabilidade de dividir texto contínuo entre páginas.',
      addPage: 'Adicionar página',
      greaterThan: 'O valor atual deve ser maior que!',
      greaterThanPrevious: 'O valor atual deve ser maior que o anterior!',
      selectFiles: 'Selecionar arquivos',
      changeSpecificCategory: 'Alterar categoria específica',
      uploadTitle:
        'Clique ou arraste o arquivo para esta área para fazer o upload',
      uploadDescription:
        'Suporte para upload único ou em massa. Estritamente proibido fazer upload de dados da empresa ou outros arquivos proibidos.',
      chunk: 'Fragmento',
      bulk: 'Em massa',
      cancel: 'Cancelar',
      rerankModel: 'Modelo de reranking',
      rerankPlaceholder: 'Por favor, selecione',
      rerankTip:
        'Se deixado vazio, o RAGFlow usará uma combinação de similaridade de palavras-chave ponderada e similaridade de cosseno vetorial ponderada; se um modelo de reranking for selecionado, uma pontuação de reranking ponderada substituirá a similaridade de cosseno vetorial ponderada. Esteja ciente de que usar um modelo de reranking aumentará significativamente o tempo de resposta do sistema.',
      topK: 'Top-K',
      topKTip: 'K fragmentos serão alimentados em modelos de reranking.',
      delimiter: 'Delimitador',
      delimiterTip:
        'Um delimitador ou separador pode consistir em um ou vários caracteres especiais. Se for múltiplos caracteres, certifique-se de que estejam entre crases (``). Por exemplo, se você configurar seus delimitadores assim: \n`##`;, seus textos serão separados em quebras de linha, símbolos de hash duplo (##) ou ponto e vírgula.',
      html4excel: 'Excel para HTML',
      html4excelTip:
        'Quando ativado, a planilha será analisada em tabelas HTML, com no máximo 256 linhas por tabela. Caso contrário, será analisada em pares chave-valor por linha.',
      autoKeywords: 'Palavras-chave automáticas',
      autoKeywordsTip:
        'Extraia automaticamente N palavras-chave para cada fragmento para aumentar sua classificação para consultas que contenham essas palavras-chave. Você pode verificar ou atualizar as palavras-chave adicionadas para um fragmento na lista de fragmentos. Esteja ciente de que tokens extras serão consumidos pelo LLM especificado nas "Configurações do modelo do sistema".',
      autoQuestions: 'Perguntas automáticas',
      autoQuestionsTip: `Extraia automaticamente N perguntas para cada fragmento para aumentar sua relevância em consultas que contenham essas perguntas. Você pode verificar ou atualizar as perguntas adicionadas a um fragmento na lista de fragmentos. Essa funcionalidade não interromperá o processo de fragmentação em caso de erro, exceto pelo fato de que pode adicionar um resultado vazio ao fragmento original. Esteja ciente de que tokens extras serão consumidos pelo LLM especificado nas 'Configurações do modelo do sistema'.`,
      redo: 'Deseja limpar os {{chunkNum}} fragmentos existentes?',
      setMetaData: 'Definir Metadados',
      pleaseInputJson: 'Por favor, insira um JSON',
      documentMetaTips: `<p>Os metadados estão no formato JSON (não são pesquisáveis). Eles serão adicionados ao prompt para o LLM se quaisquer fragmentos deste documento forem incluídos no prompt.</p>
      <p>Exemplos:</p>
      <b>Os metadados são:</b><br>
      <code>
        {
            "Autor": "Alex Dowson",
            "Data": "2024-11-12"
        }
      </code><br>
      <b>O prompt será:</b><br>
      <p>Documento: nome_do_documento</p>
      <p>Autor: Alex Dowson</p>
      <p>Data: 2024-11-12</p>
      <p>Fragmentos relevantes a seguir:</p>
      <ul>
      <li> Aqui está o conteúdo do fragmento....</li>
      <li> Aqui está o conteúdo do fragmento....</li>
      </ul>
      `,
      metaData: 'Metadados',
    },
    knowledgeConfiguration: {
      titleDescription:
        'Atualize a configuração da sua base de conhecimento aqui, especialmente o método de fragmentação.',
      name: 'Nome da base de conhecimento',
      photo: 'Foto da base de conhecimento',
      description: 'Descrição',
      language: 'Idioma',
      languageMessage: 'Por favor, insira seu idioma!',
      languagePlaceholder: 'Por favor, insira seu idioma!',
      permissions: 'Permissões',
      embeddingModel: 'Modelo de incorporação',
      chunkTokenNumber: 'Número de tokens por fragmento',
      chunkTokenNumberMessage: 'O número de tokens por fragmento é obrigatório',
      embeddingModelTip:
        'O modelo que converte fragmentos em embeddings. Ele não pode ser alterado depois que a base de conhecimento tiver fragmentos. Para mudar para um modelo diferente, é necessário excluir todos os fragmentos existentes.',
      permissionsTip:
        "Se definido como 'Equipe', todos os membros da equipe poderão gerenciar a base de conhecimento.",
      chunkTokenNumberTip:
        'Define o limite de tokens para um fragmento. Um parágrafo com menos tokens que esse limite será combinado com o próximo parágrafo até que a contagem de tokens ultrapasse o limite, momento em que um fragmento será criado.',
      chunkMethod: 'Método de fragmentação',
      chunkMethodTip: 'Veja as dicas à direita.',
      upload: 'Enviar',
      english: 'Inglês',
      chinese: 'Chinês',
      portugueseBr: 'Português (Brasil)',
      embeddingModelPlaceholder:
        'Por favor, selecione um modelo de incorporação',
      chunkMethodPlaceholder: 'Por favor, selecione um método de fragmentação',
      save: 'Salvar',
      me: 'Somente eu',
      team: 'Equipe',
      cancel: 'Cancelar',
      methodTitle: 'Descrição do método de fragmentação',
      methodExamples: 'Exemplos',
      methodExamplesDescription:
        'As capturas de tela a seguir são fornecidas para maior clareza.',
      dialogueExamplesTitle: 'Exemplos de diálogos',
      methodEmpty:
        'Aqui será exibida uma explicação visual das categorias da base de conhecimento',
      book: `<p>Os formatos de arquivo suportados são <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Para cada livro em PDF, defina os <i>intervalos de páginas</i> para remover informações indesejadas e reduzir o tempo de análise.</p>`,
      laws: `<p>Os formatos de arquivo suportados são <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Documentos legais normalmente seguem um formato de escrita rigoroso. Usamos recursos textuais para identificar pontos de divisão.</p><p>
      Os fragmentos terão granularidade compatível com 'ARTIGO', garantindo que todo o texto de nível superior seja incluído no fragmento.</p>`,
      manual: `<p>Apenas <b>PDF</b> é suportado.</p><p>
      Assumimos que o manual tem uma estrutura hierárquica de seções, usando os títulos das seções inferiores como unidade básica para fragmentação. Assim, figuras e tabelas na mesma seção não serão separadas, o que pode resultar em fragmentos maiores.</p>`,
      naive: `<p>Os formatos de arquivo suportados são <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
      <p>Este método fragmenta arquivos de maneira 'simples':</p>
      <p>
      <li>Usa um modelo de detecção visual para dividir os textos em segmentos menores.</li>
      <li>Depois, combina segmentos adjacentes até que a contagem de tokens ultrapasse o limite definido em 'Número de tokens por fragmento', criando então um fragmento.</li></p>`,
      paper: `<p>Apenas <b>PDF</b> é suportado.</p><p>
      Os artigos serão divididos por seção, como <i>resumo, 1.1, 1.2</i>. </p><p>
      Essa abordagem permite que o LLM resuma o artigo de forma mais eficaz e forneça respostas mais compreensíveis. No entanto, aumenta o contexto para conversas de IA e o custo computacional do LLM. Durante uma conversa, considere reduzir o valor de '<b>topN</b>'.</p>`,
      presentation: `<p>Os formatos de arquivo suportados são <b>PDF</b>, <b>PPTX</b>.</p><p>
      Cada página do slide é tratada como um fragmento, com sua imagem em miniatura armazenada.</p><p>
      <i>Esse método de fragmentação é aplicado automaticamente a todos os arquivos PPT enviados, então não é necessário especificá-lo manualmente.</i></p>`,
      qa: `<p>Este método suporta arquivos nos formatos <b>EXCEL</b> e <b>CSV/TXT</b>.</p>
      <li>Se o arquivo estiver no formato <b>Excel</b>, ele deve conter duas colunas sem cabeçalhos: uma para perguntas e outra para respostas, com a coluna de perguntas antes da de respostas. Múltiplas planilhas são aceitas, desde que as colunas estejam corretamente estruturadas.</li>
      <li>Se o arquivo estiver no formato <b>CSV/TXT</b>, ele deve estar codificado em UTF-8 e usar TAB como delimitador para separar perguntas e respostas.</li>
      <p><i>Linhas de texto que não seguirem essas regras serão ignoradas, e cada par de Pergunta & Resposta será tratado como um fragmento distinto.</i></p>`,
      useRaptor: 'Usar RAPTOR para melhorar a recuperação',
      useRaptorTip:
        'Processamento Abstrativo Recursivo para Recuperação Organizada em Árvore. Veja mais em https://huggingface.co/papers/2401.18059.',
      prompt: 'Prompt',
      promptTip: 'Prompt usado pelo LLM para sumarização.',
      promptMessage: 'O prompt é obrigatório',
      promptText: `Por favor, resuma os seguintes parágrafos. Tenha cuidado com os números, não invente informações. Os parágrafos são os seguintes:
      {cluster_content}
    O conteúdo acima precisa ser resumido.`,
      maxToken: 'Máximo de tokens',
      maxTokenTip: 'Número máximo de tokens para sumarização.',
      maxTokenMessage: 'O número máximo de tokens é obrigatório',
      threshold: 'Limite',
      thresholdTip: 'Quanto maior o limite, menor será o número de clusters.',
      thresholdMessage: 'O limite é obrigatório',
      maxCluster: 'Máximo de clusters',
      maxClusterTip: 'Número máximo de clusters.',
      maxClusterMessage: 'O número máximo de clusters é obrigatório',
      randomSeed: 'Semente aleatória',
      randomSeedMessage: 'A semente aleatória é obrigatória',
      entityTypes: 'Tipos de entidades',
      vietnamese: 'Vietnamita',
      pageRank: 'Ranking de páginas',
      pageRankTip: `Isso aumenta a pontuação de relevância da base de conhecimento. Seu valor será adicionado à pontuação de relevância de todos os fragmentos recuperados dessa base de conhecimento. Útil ao pesquisar em múltiplas bases e querer priorizar uma específica.`,
      tagName: 'Etiqueta',
      frequency: 'Frequência',
      searchTags: 'Pesquisar etiquetas',
      tagCloud: 'Nuvem',
      tagTable: 'Tabela',
      tagSet: 'Conjunto de etiquetas',
      tagSetTip: `
      <p>Selecionar bases de conhecimento com 'Etiqueta' ajuda a etiquetar fragmentos.</p>
      <p>Consultas nesses fragmentos também usarão etiquetas.</p>
      <p>Isso melhora a precisão da recuperação adicionando mais informações ao conjunto de dados, especialmente quando há muitos fragmentos.</p>
      <p>Diferenças entre etiquetas e palavras-chave:</p>
      <ul>
        <li>As etiquetas são um conjunto fechado definido pelo usuário, enquanto palavras-chave são um conjunto aberto.</li>
        <li>É necessário enviar conjuntos de etiquetas com exemplos antes de usá-los.</li>
        <li>Palavras-chave são geradas pelo LLM, o que é caro e demorado.</li>
      </ul>`,
      topnTags: 'Top-N Etiquetas',
      tags: 'Etiquetas',
      addTag: 'Adicionar etiqueta',
    },
    chunk: {
      chunk: 'Fragmento',
      bulk: 'Em massa',
      selectAll: 'Selecionar tudo',
      enabledSelected: 'Ativar selecionados',
      disabledSelected: 'Desativar selecionados',
      deleteSelected: 'Excluir selecionados',
      search: 'Pesquisar',
      all: 'Todos',
      enabled: 'Ativado',
      disabled: 'Desativado',
      keyword: 'Palavra-chave',
      function: 'Função',
      chunkMessage: 'Por favor, insira um valor!',
      full: 'Texto completo',
      ellipse: 'Reticências',
      graph: 'Grafo de conhecimento',
      mind: 'Mapa mental',
      question: 'Pergunta',
      questionTip: `Se houver perguntas fornecidas, a incorporação do fragmento será baseada nelas.`,
    },
    chat: {
      newConversation: 'Nova conversa',
      createAssistant: 'Criar um Assistente',
      assistantSetting: 'Configuração do Assistente',
      promptEngine: 'Motor de Prompt',
      modelSetting: 'Configuração do Modelo',
      chat: 'Chat',
      newChat: 'Novo chat',
      send: 'Enviar',
      sendPlaceholder: 'Envie uma mensagem para o assistente...',
      chatConfiguration: 'Configuração do Chat',
      chatConfigurationDescription:
        'Configure aqui um assistente de chat dedicado às bases de conhecimento selecionadas! 💕',
      assistantName: 'Nome do assistente',
      assistantNameMessage: 'O nome do assistente é obrigatório',
      namePlaceholder: 'Ex.: Jarvis Currículos',
      assistantAvatar: 'Avatar do assistente',
      language: 'Idioma',
      emptyResponse: 'Resposta vazia',
      emptyResponseTip: `Defina isto como uma resposta caso nenhuma informação seja encontrada nas bases de conhecimento para sua consulta, ou deixe este campo vazio para permitir que o LLM improvise quando nada for encontrado.`,
      setAnOpener: 'Saudação inicial',
      setAnOpenerInitial: `Olá! Sou seu assistente, como posso ajudar?`,
      setAnOpenerTip: 'Defina uma saudação inicial para os usuários.',
      knowledgeBases: 'Bases de conhecimento',
      knowledgeBasesMessage: 'Por favor, selecione',
      knowledgeBasesTip:
        'Selecione as bases de conhecimento para associar a este assistente de chat.',
      system: 'Sistema',
      systemInitialValue: `Você é um assistente inteligente. Por favor, resuma o conteúdo da base de conhecimento para responder à pergunta. Liste os dados da base de conhecimento e responda com detalhes. Quando todo o conteúdo da base de conhecimento for irrelevante para a pergunta, sua resposta deve incluir a frase "A resposta que você procura não foi encontrada na base de conhecimento!" As respostas devem considerar o histórico do chat.
      Aqui está a base de conhecimento:
      {knowledge}
      O acima é a base de conhecimento.`,
      systemMessage: 'Por favor, insira!',
      systemTip:
        'Seus prompts ou instruções para o LLM, incluindo, mas não se limitando, ao papel, ao comprimento desejado, ao tom e ao idioma das respostas.',
      topN: 'Top N',
      topNTip: `Nem todos os fragmentos com pontuação de similaridade acima do 'limiar de similaridade' serão enviados ao LLM. Isso seleciona os 'Top N' fragmentos recuperados.`,
      variable: 'Variável',
      variableTip: `As variáveis podem ajudar a desenvolver estratégias mais flexíveis, especialmente ao usar nossas APIs de gerenciamento de assistentes de chat. Essas variáveis serão usadas pelo 'Sistema' como parte dos prompts para o LLM. A variável {knowledge} é uma variável especial reservada que representa suas bases de conhecimento selecionadas, e todas as variáveis devem estar entre chaves { }.`,
      add: 'Adicionar',
      key: 'Chave',
      optional: 'Opcional',
      operation: 'Operação',
      model: 'Modelo',
      modelTip: 'Modelo de chat com linguagem ampla',
      modelMessage: 'Por favor, selecione!',
      freedom: 'Liberdade',
      improvise: 'Improvisar',
      precise: 'Preciso',
      balance: 'Equilíbrio',
      freedomTip: `Um atalho para as configurações de 'Temperatura', 'Top P', 'Penalidade de presença' e 'Penalidade de frequência', indicando o nível de liberdade do modelo. Este parâmetro possui três opções: selecione 'Improvisar' para respostas mais criativas; selecione 'Preciso' (padrão) para respostas mais conservadoras; 'Equilíbrio' é um meio-termo entre 'Improvisar' e 'Preciso'.`,
      temperature: 'Temperatura',
      temperatureMessage: 'A temperatura é obrigatória',
      temperatureTip: `Este parâmetro controla a aleatoriedade das previsões do modelo. Uma temperatura mais baixa resulta em respostas mais conservadoras, enquanto uma temperatura mais alta gera respostas mais criativas e diversificadas.`,
      topP: 'Top P',
      topPMessage: 'Top P é obrigatório',
      topPTip:
        'Também conhecido como "amostragem por núcleo", este parâmetro define um limite para selecionar um conjunto menor das palavras mais prováveis para amostragem, cortando as menos prováveis.',
      presencePenalty: 'Penalidade de presença',
      presencePenaltyMessage: 'Penalidade de presença é obrigatória',
      presencePenaltyTip:
        'Isso desencoraja o modelo de repetir as mesmas informações, penalizando palavras que já apareceram na conversa.',
      frequencyPenalty: 'Penalidade de frequência',
      frequencyPenaltyMessage: 'Penalidade de frequência é obrigatória',
      frequencyPenaltyTip:
        'Semelhante à penalidade de presença, isso reduz a tendência do modelo de repetir as mesmas palavras frequentemente.',
      maxTokens: 'Máximo de tokens',
      maxTokensMessage: 'O máximo de tokens é obrigatório',
      maxTokensTip: `Define o comprimento máximo da saída do modelo, medido pelo número de tokens (palavras ou partes de palavras). O padrão é 512. Se desativado, você remove o limite máximo de tokens, permitindo que o modelo determine o número de tokens em suas respostas.`,
      maxTokensInvalidMessage:
        'Por favor, insira um número válido para o máximo de tokens.',
      maxTokensMinMessage: 'O máximo de tokens não pode ser menor que 0.',
      quote: 'Mostrar citação',
      quoteTip: 'Exibir ou não o texto original como referência.',
      selfRag: 'Self-RAG',
      selfRagTip: 'Consulte: https://huggingface.co/papers/2310.11511',
      overview: 'ID do Chat',
      pv: 'Número de mensagens',
      uv: 'Número de usuários ativos',
      speed: 'Velocidade de saída de tokens',
      tokens: 'Consumo de tokens',
      round: 'Número de interações por sessão',
      thumbUp: 'Satisfação do cliente',
      preview: 'Pré-visualizar',
      embedded: 'Incorporado',
      serviceApiEndpoint: 'Endpoint da API de Serviço',
      apiKey: 'Chave da API',
      apiReference: 'Documentos da API',
      dateRange: 'Intervalo de datas:',
      backendServiceApi: 'Servidor API',
      createNewKey: 'Criar nova chave',
      created: 'Criado',
      action: 'Ação',
      embedModalTitle: 'Incorporar no site',
      comingSoon: 'Em breve',
      fullScreenTitle: 'Incorporar Total',
      fullScreenDescription:
        'Incorpore o iframe abaixo no local desejado em seu site',
      partialTitle: 'Incorporar Parcial',
      extensionTitle: 'Extensão do Chrome',
      tokenError: 'Por favor, crie uma chave API primeiro.',
      betaError:
        'Por favor, adquira uma chave API do RAGFlow na página Configurações do Sistema primeiro.',
      searching: 'Pesquisando...',
      parsing: 'Analisando',
      uploading: 'Carregando',
      uploadFailed: 'Falha no carregamento',
      regenerate: 'Regenerar',
      read: 'Ler conteúdo',
      tts: 'Texto para fala',
      ttsTip:
        'Certifique-se de selecionar um modelo TTS na página Configurações antes de habilitar esta opção para reproduzir texto como áudio.',
      relatedQuestion: 'Pergunta relacionada',
      answerTitle: 'R',
      multiTurn: 'Otimização de múltiplas interações',
      multiTurnTip:
        'Isso otimiza as consultas dos usuários usando o contexto em uma conversa de múltiplas rodadas. Quando ativado, consumirá tokens adicionais do LLM.',
      howUseId: 'Como usar o ID do chat?',
      description: 'Descrição do assistente',
    },
    setting: {
      profile: 'Perfil',
      profileDescription: 'Atualize sua foto e detalhes pessoais aqui.',
      maxTokens: 'Máximo de Tokens',
      maxTokensMessage: 'Máximo de Tokens é obrigatório',
      maxTokensTip: `Isso define o comprimento máximo da saída do modelo, medido em número de tokens (palavras ou partes de palavras). O padrão é 512. Se desativado, você remove o limite máximo de tokens, permitindo que o modelo determine o número de tokens em suas respostas.`,
      maxTokensInvalidMessage:
        'Por favor, insira um número válido para Máximo de Tokens.',
      maxTokensMinMessage: 'O Máximo de Tokens não pode ser menor que 0.',
      password: 'Senha',
      passwordDescription: 'Digite sua senha atual para alterá-la.',
      model: 'Provedores de Modelo',
      modelDescription: 'Defina o parâmetro do modelo e a chave da API aqui.',
      team: 'Equipe',
      system: 'Sistema',
      logout: 'Sair',
      api: 'API',
      username: 'Nome de usuário',
      usernameMessage: 'Por favor, insira seu nome de usuário!',
      photo: 'Sua foto',
      photoDescription: 'Isso será exibido no seu perfil.',
      colorSchema: 'Esquema de cores',
      colorSchemaMessage: 'Por favor, selecione seu esquema de cores!',
      colorSchemaPlaceholder: 'selecione seu esquema de cores',
      bright: 'Claro',
      dark: 'Escuro',
      timezone: 'Fuso horário',
      timezoneMessage: 'Por favor, insira seu fuso horário!',
      timezonePlaceholder: 'selecione seu fuso horário',
      email: 'Endereço de e-mail',
      emailDescription: 'Após o registro, o e-mail não pode ser alterado.',
      currentPassword: 'Senha atual',
      currentPasswordMessage: 'Por favor, insira sua senha!',
      newPassword: 'Nova senha',
      newPasswordMessage: 'Por favor, insira sua nova senha!',
      newPasswordDescription: 'Sua nova senha deve ter mais de 8 caracteres.',
      confirmPassword: 'Confirmar nova senha',
      confirmPasswordMessage: 'Por favor, confirme sua senha!',
      confirmPasswordNonMatchMessage:
        'A nova senha que você inseriu não corresponde!',
      cancel: 'Cancelar',
      addedModels: 'Modelos adicionados',
      modelsToBeAdded: 'Modelos a serem adicionados',
      addTheModel: 'Adicionar o modelo',
      apiKey: 'Chave da API',
      apiKeyMessage:
        'Por favor, insira a chave da API (para modelos implantados localmente, ignore isso).',
      apiKeyTip:
        'A chave da API pode ser obtida registrando-se no fornecedor correspondente do LLM.',
      showMoreModels: 'Mostrar mais modelos',
      baseUrl: 'URL Base',
      baseUrlTip:
        'Se sua chave da API for do OpenAI, ignore isso. Outros provedores intermediários fornecerão essa URL base com a chave da API.',
      modify: 'Modificar',
      systemModelSettings: 'Configurações do Modelo do Sistema',
      chatModel: 'Modelo de chat',
      chatModelTip:
        'O modelo LLM padrão que todos os novos bancos de conhecimento usarão.',
      embeddingModel: 'Modelo de incorporação',
      embeddingModelTip:
        'O modelo de incorporação padrão que todos os novos bancos de conhecimento usarão.',
      img2txtModel: 'Modelo Img2Txt',
      img2txtModelTip:
        'O modelo multimodal padrão que todos os novos bancos de conhecimento usarão. Ele pode descrever uma imagem ou vídeo.',
      sequence2txtModel: 'Modelo Sequence2Txt',
      sequence2txtModelTip:
        'O modelo ASR padrão que todos os novos bancos de conhecimento usarão. Use este modelo para converter vozes em texto correspondente.',
      rerankModel: 'Modelo de Reordenação',
      rerankModelTip:
        'O modelo de reordenação padrão usado para reordenar os fragmentos recuperados pelas perguntas dos usuários.',
      ttsModel: 'Modelo TTS',
      ttsModelTip:
        'O modelo TTS padrão será usado para gerar fala durante as conversas sob demanda.',
      workspace: 'Área de trabalho',
      upgrade: 'Atualizar',
      addLlmTitle: 'Adicionar LLM',
      modelName: 'Nome do modelo',
      modelID: 'ID do modelo',
      modelUid: 'UID do modelo',
      modelNameMessage: 'Por favor, insira o nome do seu modelo!',
      modelType: 'Tipo de modelo',
      modelTypeMessage: 'Por favor, insira o tipo do seu modelo!',
      addLlmBaseUrl: 'URL base',
      baseUrlNameMessage: 'Por favor, insira sua URL base!',
      vision: 'Suporta visão?',
      ollamaLink: 'Como integrar {{name}}',
      FishAudioLink: 'Como usar FishAudio',
      TencentCloudLink: 'Como usar TencentCloud ASR',
      volcModelNameMessage: 'Por favor, insira o nome do seu modelo!',
      addEndpointID: 'EndpointID do modelo',
      endpointIDMessage: 'Por favor, insira o EndpointID do modelo',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Por favor, insira sua ARK_API_KEY',
      bedrockModelNameMessage: 'Por favor, insira o nome do seu modelo!',
      addBedrockEngineAK: 'CHAVE DE ACESSO',
      bedrockAKMessage: 'Por favor, insira sua CHAVE DE ACESSO',
      addBedrockSK: 'CHAVE SECRETA',
      bedrockSKMessage: 'Por favor, insira sua CHAVE SECRETA',
      bedrockRegion: 'Região AWS',
      bedrockRegionMessage: 'Por favor, selecione!',
      'us-east-1': 'Leste dos EUA (N. Virgínia)',
      'us-west-2': 'Oeste dos EUA (Oregon)',
      'ap-southeast-1': 'Ásia-Pacífico (Singapura)',
      'ap-northeast-1': 'Ásia-Pacífico (Tóquio)',
      'eu-central-1': 'Europa (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (EUA-Oeste)',
      'ap-southeast-2': 'Ásia-Pacífico (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Por favor, insira seu Secret ID',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Por favor, insira sua Secret Key',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Por favor, insira seu Secret ID',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Por favor, insira sua Secret Key',
      SparkModelNameMessage: 'Por favor, selecione o modelo Spark',
      addSparkAPIPassword: 'Senha da API Spark',
      SparkAPIPasswordMessage: 'Por favor, insira sua senha da API',
      addSparkAPPID: 'ID do aplicativo Spark',
      SparkAPPIDMessage: 'Por favor, insira seu ID do aplicativo',
      addSparkAPISecret: 'Segredo da API Spark',
      SparkAPISecretMessage: 'Por favor, insira seu segredo da API',
      addSparkAPIKey: 'Chave da API Spark',
      SparkAPIKeyMessage: 'Por favor, insira sua chave da API',
      yiyanModelNameMessage: 'Por favor, insira o nome do modelo',
      addyiyanAK: 'Chave da API yiyan',
      yiyanAKMessage: 'Por favor, insira sua chave da API',
      addyiyanSK: 'Chave secreta yiyan',
      yiyanSKMessage: 'Por favor, insira sua chave secreta',
      FishAudioModelNameMessage:
        'Por favor, dê um nome ao seu modelo de síntese de voz',
      addFishAudioAK: 'Chave da API Fish Audio',
      addFishAudioAKMessage: 'Por favor, insira sua chave da API',
      addFishAudioRefID: 'ID de referência do FishAudio',
      addFishAudioRefIDMessage:
        'Por favor, insira o ID de referência (deixe em branco para usar o modelo padrão).',
      modelProvidersWarn: `Por favor, adicione tanto o modelo de incorporação quanto o LLM em <b>Configurações > Provedores de Modelo</b> primeiro. Depois, defina-os nas 'Configurações do modelo do sistema'.`,
      apiVersion: 'Versão da API',
      apiVersionMessage: 'Por favor, insira a versão da API',
      add: 'Adicionar',
      updateDate: 'Data de atualização',
      role: 'Função',
      invite: 'Convidar',
      agree: 'Aceitar',
      refuse: 'Recusar',
      teamMembers: 'Membros da equipe',
      joinedTeams: 'Equipes ingressadas',
      sureDelete: 'Tem certeza de que deseja remover este membro?',
      quit: 'Sair',
      sureQuit: 'Tem certeza de que deseja sair da equipe que você ingressou?',
    },
    message: {
      registered: 'Registrado!',
      logout: 'sair',
      logged: 'conectado!',
      pleaseSelectChunk: 'Por favor, selecione o pedaço!',
      modified: 'Modificado',
      created: 'Criado',
      deleted: 'Deletado',
      renamed: 'Renomeado',
      operated: 'Operado',
      updated: 'Atualizado',
      uploaded: 'Carregado',
      200: 'O servidor retornou com sucesso os dados solicitados.',
      201: 'Dados criados ou modificados com sucesso.',
      202: 'Uma solicitação foi colocada na fila em segundo plano (tarefa assíncrona).',
      204: 'Dados deletados com sucesso.',
      400: 'Houve um erro na solicitação emitida, e o servidor não criou ou modificou os dados.',
      401: 'O usuário não tem permissões (token, nome de usuário ou senha incorretos).',
      403: 'O usuário está autorizado, mas o acesso é proibido.',
      404: 'A solicitação foi feita para um registro que não existe, e o servidor não executou a operação.',
      406: 'O formato solicitado não está disponível.',
      410: 'O recurso solicitado foi permanentemente deletado e não estará mais disponível.',
      413: 'O tamanho total dos arquivos carregados de uma vez é muito grande.',
      422: 'Ao criar um objeto, ocorreu um erro de validação.',
      500: 'Ocorreu um erro no servidor, por favor, verifique o servidor.',
      502: 'Erro de gateway.',
      503: 'O serviço está indisponível e o servidor está temporariamente sobrecarregado ou em manutenção.',
      504: 'Timeout de gateway.',
      requestError: 'Erro na solicitação',
      networkAnomalyDescription:
        'Há uma anomalia na sua rede e você não consegue se conectar ao servidor.',
      networkAnomaly: 'anomalia de rede',
      hint: 'dica',
    },
    fileManager: {
      name: 'Nome',
      uploadDate: 'Data de Upload',
      knowledgeBase: 'Base de Conhecimento',
      size: 'Tamanho',
      action: 'Ação',
      addToKnowledge: 'Link para a Base de Conhecimento',
      pleaseSelect: 'Por favor, selecione',
      newFolder: 'Nova Pasta',
      file: 'Arquivo',
      uploadFile: 'Carregar Arquivo',
      directory: 'Diretório',
      uploadTitle:
        'Clique ou arraste o arquivo para esta área para fazer o upload',
      uploadDescription:
        'Suporta upload de um único arquivo ou múltiplos arquivos. É estritamente proibido o upload de dados da empresa ou outros arquivos proibidos.',
      local: 'Uploads locais',
      s3: 'Uploads S3',
      preview: 'Pré-visualização',
      fileError: 'Erro no arquivo',
      uploadLimit:
        'O tamanho do arquivo não pode exceder 10M, e o número total de arquivos não pode exceder 128',
      destinationFolder: 'Pasta de destino',
    },
    flow: {
      cite: 'Citar',
      citeTip: 'dicaDeCitação',
      name: 'Nome',
      nameMessage: 'Por favor, insira o nome',
      description: 'Descrição',
      examples: 'Exemplos',
      to: 'Para',
      msg: 'Mensagens',
      messagePlaceholder: 'mensagem',
      messageMsg: 'Por favor, insira uma mensagem ou delete este campo.',
      addField: 'Adicionar opção',
      addMessage: 'Adicionar mensagem',
      loop: 'Loop',
      loopTip:
        'O loop é o limite superior do número de repetições do componente atual. Quando o número de repetições excede o valor do loop, significa que o componente não pode completar a tarefa atual, por favor, reotimize o agente',
      yes: 'Sim',
      no: 'Não',
      key: 'Chave',
      componentId: 'ID do componente',
      add: 'Adicionar',
      operation: 'Operação',
      run: 'Executar',
      save: 'Salvar',
      title: 'ID:',
      beginDescription: 'Aqui é onde o fluxo começa.',
      answerDescription: `Um componente que serve como a interface entre o ser humano e o bot, recebendo entradas do usuário e exibindo as respostas do agente.`,
      retrievalDescription: `Um componente que recupera informações de bases de conhecimento especificadas (conjuntos de dados). Certifique-se de que as bases de conhecimento que você seleciona utilizam o mesmo modelo de incorporação.`,
      generateDescription: `Um componente que solicita ao LLM gerar respostas. Certifique-se de que o prompt está configurado corretamente.`,
      categorizeDescription: `Um componente que utiliza o LLM para classificar as entradas do usuário em categorias predefinidas. Certifique-se de especificar o nome, a descrição e os exemplos para cada categoria, juntamente com o próximo componente correspondente.`,
      relevantDescription: `Um componente que usa o LLM para avaliar se a saída anterior é relevante para a última consulta do usuário. Certifique-se de especificar o próximo componente para cada resultado de avaliação.`,
      rewriteQuestionDescription: `Um componente que refina uma consulta do usuário caso ela não recupere informações relevantes da base de conhecimento. Esse processo é repetido até que o limite de repetições predefinido seja alcançado.`,
      messageDescription:
        "Um componente que envia uma mensagem estática. Se várias mensagens forem fornecidas, uma delas será selecionada aleatoriamente para ser enviada. Certifique-se de que o componente posterior seja 'Interagir', o componente de interface.",
      keywordDescription: `Um componente que recupera os N melhores resultados de pesquisa a partir da entrada do usuário. Certifique-se de que o valor de TopN esteja configurado corretamente antes de usar.`,
      switchDescription: `Um componente que avalia condições com base na saída de componentes anteriores e direciona o fluxo de execução conforme necessário. Ele permite lógica de ramificação complexa, definindo casos e especificando ações para cada caso ou ação padrão caso nenhuma condição seja atendida.`,
      wikipediaDescription: `Um componente que realiza buscas no wikipedia.org, utilizando TopN para especificar o número de resultados de pesquisa. Ele complementa as bases de conhecimento existentes.`,
      promptText: `Por favor, resuma os seguintes parágrafos. Tenha cuidado com os números, não invente nada. Os parágrafos são os seguintes:
        {input} Acima está o conteúdo que você precisa resumir.`,
      createGraph: 'Criar agente',
      createFromTemplates: 'Criar a partir de modelos',
      retrieval: 'Recuperação',
      generate: 'Gerar',
      answer: 'Interagir',
      categorize: 'Categorizar',
      relevant: 'Relevante',
      rewriteQuestion: 'Reescrever',
      rewrite: 'Reescrever',
      begin: 'Começar',
      message: 'Mensagem',
      blank: 'Em branco',
      createFromNothing: 'Crie seu agente do zero',
      addItem: 'Adicionar Item',
      addSubItem: 'Adicionar Subitem',
      nameRequiredMsg: 'Nome é obrigatório',
      nameRepeatedMsg: 'O nome não pode ser repetido',
      keywordExtract: 'Palavra-chave',
      keywordExtractDescription: `Um componente que extrai palavras-chave de uma consulta do usuário, com Top N especificando o número de palavras-chave a serem extraídas.`,
      baidu: 'Baidu',
      baiduDescription: `Um componente que realiza buscas no baidu.com, utilizando TopN para especificar o número de resultados de pesquisa. Ele complementa as bases de conhecimento existentes.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Um componente que realiza buscas no duckduckgo.com, permitindo especificar o número de resultados de pesquisa usando TopN. Ele complementa as bases de conhecimento existentes.',
      channel: 'Canal',
      channelTip: `Realize uma busca por texto ou por notícias na entrada do componente`,
      text: 'Texto',
      news: 'Notícias',
      messageHistoryWindowSize: 'Tamanho da janela de mensagens',
      messageHistoryWindowSizeTip:
        'O tamanho da janela do histórico de conversa que precisa ser visualizado pelo LLM. Quanto maior, melhor. Mas tenha cuidado com o comprimento máximo de conteúdo do LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      pubMedDescription:
        'Um componente que realiza buscas em https://pubmed.ncbi.nlm.nih.gov/, permitindo especificar o número de resultados de pesquisa usando TopN. Ele complementa as bases de conhecimento existentes.',
      email: 'Email',
      emailTip:
        'O E-mail é um campo obrigatório. Você deve inserir um endereço de E-mail aqui.',
      arXiv: 'ArXiv',
      arXivDescription:
        'Um componente que realiza buscas em https://arxiv.org/, permitindo especificar o número de resultados de pesquisa usando TopN. Ele complementa as bases de conhecimento existentes.',
      sortBy: 'Ordenar por',
      submittedDate: 'Data de envio',
      lastUpdatedDate: 'Última data de atualização',
      relevance: 'Relevância',
      google: 'Google',
      googleDescription:
        'Um componente que realiza buscas em https://www.google.com/, permitindo especificar o número de resultados de pesquisa usando TopN. Ele complementa as bases de conhecimento existentes. Observe que isso requer uma chave API de serpapi.com.',
      bing: 'Bing',
      bingDescription:
        'Um componente que realiza buscas em https://www.bing.com/, permitindo especificar o número de resultados de pesquisa usando TopN. Ele complementa as bases de conhecimento existentes. Observe que isso requer uma chave API de microsoft.com.',
      apiKey: 'CHAVE API',
      country: 'País&Região',
      language: 'Idioma',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Um componente que realiza buscas em https://scholar.google.com/. Você pode usar Top N para especificar o número de resultados de pesquisa.',
      yearLow: 'Ano mínimo',
      yearHigh: 'Ano máximo',
      patents: 'Patentes',
      data: 'Dados',
      deepL: 'DeepL',
      deepLDescription:
        'Um componente que realiza traduções mais especializadas a partir de https://www.deepl.com/.',
      authKey: 'Chave de autenticação',
      sourceLang: 'Idioma de origem',
      targetLang: 'Idioma de destino',
      gitHub: 'GitHub',
      gitHubDescription:
        'Um componente que realiza buscas por repositórios em https://github.com/. Você pode usar Top N para especificar o número de resultados de pesquisa.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Um componente que realiza traduções especializadas a partir de https://fanyi.baidu.com/.',
      appid: 'ID do App',
      secretKey: 'Chave secreta',
      domain: 'Domínio',
      transType: 'Tipo de tradução',
      baiduSecretKeyOptions: {
        translate: 'Tradução geral',
        fieldtranslate: 'Tradução de campo',
      },
      baiduDomainOptions: {
        it: 'Tecnologia da informação',
        finance: 'Finanças e economia',
        machinery: 'Fabricação de máquinas',
        senimed: 'Biomedicina',
        novel: 'Literatura online',
        academic: 'Artigo acadêmico',
        aerospace: 'Aeroespacial',
        wiki: 'Humanidades e ciências sociais',
        news: 'Notícias e informações',
        law: 'Leis e regulamentos',
        contract: 'Contrato',
      },
      baiduSourceLangOptions: {
        auto: 'Detecção automática',
        zh: 'Chinês',
        en: 'Inglês',
        yue: 'Cantonês',
        wyw: 'Chinês clássico',
        jp: 'Japonês',
        kor: 'Coreano',
        fra: 'Francês',
        spa: 'Espanhol',
        th: 'Tailandês',
        ara: 'Árabe',
        ru: 'Russo',
        pt: 'Português',
        de: 'Alemão',
        it: 'Italiano',
        el: 'Grego',
        nl: 'Holandês',
        pl: 'Polonês',
        bul: 'Búlgaro',
        est: 'Estoniano',
        dan: 'Dinamarquês',
        fin: 'Finlandês',
        cs: 'Tcheco',
        rom: 'Romeno',
        slo: 'Esloveno',
        swe: 'Sueco',
        hu: 'Húngaro',
        cht: 'Chinês tradicional',
        vie: 'Vietnamita',
      },

      qWeather: 'QWeather',
      qWeatherDescription:
        'Um componente que recupera informações meteorológicas, como temperatura e qualidade do ar, de https://www.qweather.com.',

      lang: 'Idioma',
      type: 'Tipo',
      webApiKey: 'Chave de API da Web',
      userType: 'Tipo de usuário',
      timePeriod: 'Período de tempo',

      qWeatherLangOptions: {
        zh: 'Chinês simplificado',
        'zh-hant': 'Chinês tradicional',
        en: 'Inglês',
        de: 'Alemão',
        es: 'Espanhol',
        fr: 'Francês',
        it: 'Italiano',
        ja: 'Japonês',
        ko: 'Coreano',
        ru: 'Russo',
        hi: 'Hindi',
        th: 'Tailandês',
        ar: 'Árabe',
        pt: 'Português',
        bn: 'Bengali',
        ms: 'Malaio',
        nl: 'Holandês',
        el: 'Grego',
        la: 'Latim',
        sv: 'Sueco',
        id: 'Indonésio',
        pl: 'Polonês',
        tr: 'Turco',
        cs: 'Tcheco',
        et: 'Estoniano',
        vi: 'Vietnamita',
        fil: 'Filipino',
        fi: 'Finlandês',
        he: 'Hebraico',
        is: 'Islandês',
        nb: 'Norueguês',
      },

      qWeatherTypeOptions: {
        weather: 'Previsão do tempo',
        indices: 'Índice de qualidade de vida relacionado ao clima',
        airquality: 'Qualidade do ar',
      },
      qWeatherUserTypeOptions: {
        free: 'Assinante gratuito',
        paid: 'Assinante pago',
      },

      qWeatherTimePeriodOptions: {
        now: 'Agora',
        '3d': '3 dias',
        '7d': '7 dias',
        '10d': '10 dias',
        '15d': '12 dias',
        '30d': '30 dias',
      },

      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Um componente que executa consultas SQL em um banco de dados relacional, suportando consultas de MySQL, PostgreSQL ou MariaDB.',

      dbType: 'Tipo de banco de dados',
      database: 'Banco de dados',
      username: 'Nome de usuário',
      host: 'Hospedeiro',
      port: 'Porta',
      password: 'Senha',
      switch: 'Trocar',
      logicalOperator: 'Operador lógico',
      switchOperatorOptions: {
        equal: 'igual',
        notEqual: 'diferente',
        gt: 'Maior que',
        ge: 'Maior ou igual',
        lt: 'Menor que',
        le: 'Menor ou igual',
        contains: 'Contém',
        notContains: 'Não contém',
        startWith: 'Começa com',
        endWith: 'Termina com',
        empty: 'Vazio',
        notEmpty: 'Não vazio',
      },

      switchLogicOperatorOptions: {
        and: 'E',
        or: 'Ou',
      },

      operator: 'Operador',
      value: 'Valor',
      useTemplate: 'Usar este modelo',
      wenCai: 'WenCai',
      queryType: 'Tipo de consulta',
      wenCaiDescription:
        'Um componente que obtém informações financeiras, incluindo preços de ações e notícias de financiamento, de uma ampla variedade de sites financeiros.',

      wenCaiQueryTypeOptions: {
        stock: 'Ação',
        zhishu: 'Índice',
        fund: 'Fundo',
        hkstock: 'Ações de Hong Kong',
        usstock: 'Mercado de ações dos EUA',
        threeboard: 'Mercado OTC Novo',
        conbond: 'Título Conversível',
        insurance: 'Seguro',
        futures: 'Futuros',
        lccp: 'Financiamento',
        foreign_exchange: 'Câmbio',
      },

      akShare: 'AkShare',
      akShareDescription:
        'Um componente que obtém notícias sobre ações de https://www.eastmoney.com/.',

      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Um componente que consulta informações sobre uma empresa de capital aberto usando seu símbolo de ticker.',

      crawler: 'Rastreador Web',
      crawlerDescription:
        'Um componente que rastreia o código-fonte HTML de um URL especificado.',

      proxy: 'Proxy',
      crawlerResultOptions: {
        html: 'Html',
        markdown: 'Markdown',
        content: 'Conteúdo',
      },

      extractType: 'Tipo de extração',
      info: 'Informações',
      history: 'Histórico',
      financials: 'Financeiro',
      balanceSheet: 'Balanço patrimonial',
      cashFlowStatement: 'Demonstração de fluxo de caixa',
      jin10: 'Jin10',
      jin10Description:
        'Um componente que obtém informações financeiras da Plataforma Aberta Jin10, incluindo notícias, calendários, cotações e referências.',

      flashType: 'Tipo de Flash',
      filter: 'Filtro',
      contain: 'Contém',
      calendarType: 'Tipo de calendário',
      calendarDatashape: 'Formato de dados do calendário',
      symbolsDatatype: 'Tipo de dados de símbolos',
      symbolsType: 'Tipo de símbolos',
      jin10TypeOptions: {
        flash: 'Notícias rápidas',
        calendar: 'Calendário',
        symbols: 'Cotações',
        news: 'Referência',
      },

      jin10FlashTypeOptions: {
        '1': 'Notícias do Mercado',
        '2': 'Notícias de Futuros',
        '3': 'Notícias EUA-Hong Kong',
        '4': 'Notícias A-Share',
        '5': 'Notícias de Commodities & Forex',
      },

      jin10CalendarTypeOptions: {
        cj: 'Calendário de dados macroeconômicos',
        qh: 'Calendário de Futuros',
        hk: 'Calendário do mercado de ações de Hong Kong',
        us: 'Calendário do mercado de ações dos EUA',
      },

      jin10CalendarDatashapeOptions: {
        data: 'Dados',
        event: 'Evento',
        holiday: 'Feriado',
      },

      jin10SymbolsTypeOptions: {
        GOODS: 'Cotações de commodities',
        FOREX: 'Cotações de Forex',
        FUTURE: 'Cotações do mercado internacional',
        CRYPTO: 'Cotações de criptomoedas',
      },

      jin10SymbolsDatatypeOptions: {
        symbols: 'Lista de commodities',
        quotes: 'Últimas cotações do mercado',
      },
      concentrator: 'Concentrador',
      concentratorDescription:
        'Um componente que recebe a saída do componente anterior e a passa como entrada para os componentes subsequentes.',

      tuShare: 'TuShare',
      tuShareDescription:
        'Um componente que obtém resumos de notícias financeiras de sites financeiros principais, auxiliando pesquisas industriais e quantitativas.',

      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Straight flush',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },

      token: 'Token',
      src: 'Fonte',
      startDate: 'Data de início',
      endDate: 'Data de término',
      keyword: 'Palavra-chave',
      note: 'Nota',
      noteDescription: 'Nota',
      notePlaceholder: 'Por favor, insira uma nota',

      invoke: 'Invocar',
      invokeDescription:
        'Um componente capaz de chamar serviços remotos, usando saídas de outros componentes ou constantes como entradas.',

      url: 'Url',
      method: 'Método',
      timeout: 'Tempo de espera',
      headers: 'Cabeçalhos',
      cleanHtml: 'Limpar HTML',
      cleanHtmlTip:
        'Se a resposta for formatada em HTML e apenas o conteúdo principal for desejado, ative esta opção.',

      reference: 'Referência',
      input: 'Entrada',
      output: 'Saída',
      parameter: 'Parâmetro',
      howUseId: 'Como usar o ID do agente?',
      content: 'Conteúdo',
      operationResults: 'Resultados da operação',
      autosaved: 'Autossalvo',
      optional: 'Opcional',
      pasteFileLink: 'Cole o link do arquivo',
      testRun: 'Executar teste',
      template: 'Modelo',
      templateDescription:
        'Um componente que formata a saída de outro componente.',

      emailComponent: 'Email',
      emailDescription: 'Enviar um email para um endereço especificado.',
      smtpServer: 'Servidor SMTP',
      smtpPort: 'Porta SMTP',
      senderEmail: 'Email do remetente',
      authCode: 'Código de autorização',
      senderName: 'Nome do remetente',
      toEmail: 'Email do destinatário',
      ccEmail: 'Email CC',
      emailSubject: 'Assunto',
      emailContent: 'Conteúdo',
      smtpServerRequired: 'Por favor, insira o endereço do servidor SMTP',
      senderEmailRequired: 'Por favor, insira o email do remetente',
      authCodeRequired: 'Por favor, insira o código de autorização',
      toEmailRequired: 'Por favor, insira o email do destinatário',
      emailContentRequired: 'Por favor, insira o conteúdo do email',
      emailSentSuccess: 'Email enviado com sucesso',
      emailSentFailed: 'Falha ao enviar o email',

      dynamicParameters: 'Parâmetros dinâmicos',
      jsonFormatTip:
        'O componente anterior deve fornecer a string JSON no seguinte formato:',
      toEmailTip: 'to_email: Email do destinatário (Obrigatório)',
      ccEmailTip: 'cc_email: Email CC (Opcional)',
      subjectTip: 'subject: Assunto do email (Opcional)',
      contentTip: 'content: Conteúdo do email (Opcional)',
      jsonUploadTypeErrorMessage: 'Por favor, carregue um arquivo json',
      jsonUploadContentErrorMessage: 'Erro no arquivo json',

      iteration: 'Iteração',
      iterationDescription:
        'Este componente primeiramente divide a entrada em um array pelo "delimitador". Realiza os mesmos passos de operação nos elementos do array em sequência até que todos os resultados sejam gerados, o que pode ser entendido como um processador de tarefas em lote. Por exemplo, dentro do nó de tradução de texto longo, se todo o conteúdo for enviado ao nó LLM, o limite de conversação pode ser atingido. O nó anterior pode primeiro dividir o texto longo em fragmentos e cooperar com o nó iterativo para realizar a tradução em lote de cada fragmento para evitar atingir o limite de mensagem do LLM em uma única conversa.',

      delimiterTip:
        'Este delimitador é usado para dividir o texto de entrada em várias partes, cuja saída será realizada como entrada de cada iteração.',

      delimiterOptions: {
        comma: 'Vírgula',
        lineBreak: 'Quebra de linha',
        tab: 'Tabulação',
        underline: 'Sublinhado',
        diagonal: 'Forward slash',
        minus: 'Dash',
        semicolon: 'Ponto e vírgula',
      },
      addVariable: 'Adicionar variável',
      variableSettings: 'Configurações da variável',
      globalVariables: 'Variáveis globais',
      systemPrompt: 'Prompt do sistema',
      addCategory: 'Adicionar categoria',
      categoryName: 'Nome da categoria',
      nextStep: 'Próximo passo',
      footer: {
        profile: 'Todos os direitos reservados @ React',
      },
      layout: {
        file: 'arquivo',
        knowledge: 'conhecimento',
        chat: 'bate-papo',
      },
    },
  },
};
